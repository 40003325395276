<template>
  <div>
    <!--  Visual Section -->
    <section
      ref="cover"
      class="section visual-section">
      <CoverTransition>
        <div class="section-bg gangnam-bg">
          <div class="title-wrap">
            <h2 class="ani-text-up">GANGNAM CENTER</h2>
            <div class="title-desc">
              <p class="ani-text-up" v-html="$t('content.center.Gangnam.txt01')">
              </p>
              <p class="ani-text-up" v-html="$t('content.center.Gangnam.txt02')">
              </p>
            </div>
          </div>
        </div>
      </CoverTransition>
    </section>
    <!--  // Visual Section -->

    <!-- FACILITIES -->
    <section class="inner-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3>FACILITIES</h3>
        </div>
        <BaseTab
          v-model="tabValue"
          v-bind:list="facilities"
          tabtype="small-tab"
          @input="tabCallback"
        >
          <template v-slot="Scope">
            <li class :class="Scope.paramClass">{{Scope.title}}</li>
          </template>
        </BaseTab>
        <div class="image-area facilities">
          <!-- 이미지 슬라이더 -->
          <BaseSlider
            class="s2"
            ref="facilitieSlider"
            :autoplay="false"
            :list="facilitieSliderList"
            :pagination="true"
            :navigation="true"
            @change="onChange"
          >
            <template v-slot="{ item }">
              <img :src="item.img" :alt="`${item.title} 이미지`" />
            </template>
          </BaseSlider>
          <!-- // 이미지 슬라이더 -->
        </div>
      </div>
    </section>
    <!-- // FACILITIES -->

    <!--AMENITIES -->
    <section class="inner-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3 class="center">AMENITIES</h3>
        </div>
        <div>
          <ul class="line-box-list ani-stagger">
            <li>
              <div class="line-box">
                <span class="icon open-kitchen"></span>
                <h6 class="h6-16">{{$t('content.center.Gangnam.txt03')}}</h6>
              </div>
            </li>
            <li>
              <div class="line-box">
                <span class="icon meeting-room"></span>
                <h6 class="h6-16">{{$t('content.center.Gangnam.txt04')}}</h6>
              </div>
            </li>
            <li>
              <div class="line-box">
                <span class="icon oa"></span>
                <h6 class="h6-16">{{$t('content.center.Gangnam.txt05')}}</h6>
              </div>
            </li>
            <li>
              <div class="line-box">
                <span class="icon phone-booth"></span>
                <h6 class="h6-16">{{$t('content.center.Gangnam.txt06')}}</h6>
              </div>
            </li>
            <li>
              <div class="line-box">
                <span class="icon storage"></span>
                <h6 class="h6-16">{{$t('content.center.Gangnam.txt07')}}</h6>
              </div>
            </li>
            <li>
              <div class="line-box">
                <span class="icon internet"></span>
                <h6 class="h6-16">{{$t('content.center.Gangnam.txt08')}}</h6>
              </div>
            </li>
            <li>
              <div class="line-box">
                <span class="icon quick-express"></span>
                <h6 class="h6-16">{{$t('content.center.Gangnam.txt09')}}</h6>
              </div>
            </li>
            <li>
              <div class="line-box">
                <span class="icon ladies-lounge"></span>
                <h6 class="h6-16">{{$t('content.center.Gangnam.txt10')}}</h6>
              </div>
            </li>
            <li>
              <div class="line-box">
                <span class="icon parking-car-sharing"></span>
                <h6 class="h6-16">{{$t('content.center.Gangnam.txt11')}}</h6>
              </div>
            </li>
            <li>
              <div class="line-box">
                <span class="icon shower"></span>
                <h6 class="h6-16">{{$t('content.center.Gangnam.txt12')}}</h6>
              </div>
            </li>
            <li>
              <div class="line-box">
                <span class="icon security-system"></span>
                <h6 class="h6-16">{{$t('content.center.Gangnam.txt13')}}</h6>
              </div>
            </li>
            <li>
              <div class="line-box">
                <span class="icon event-hall"></span>
                <h6 class="h6-16">{{$t('content.center.Gangnam.txt14')}}</h6>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!--// AMENITIES -->

    <!-- KEY PLAYERS -->
    <section class="grey-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3>KEY PLAYERS</h3>
          <div class="title-desc" v-html="$t('content.center.Gangnam.txt15')">
          </div>
        </div>
        <!-- KEY PLAYERS List -->
        <div class="card-list-wrap">
          <div class="card-list">
            <!-- Swiper -->
            <GroupedSlider
              :pagination="false"
              :autoplay="false"
              :loop="false"
              :list="memberList"
              :itemsPerGroup="12"
            >
              <template v-slot="{ item }">
                <div class="card-wrap">
                  <div class="card">
                    <img v-if="item.img" :src="item.img" :alt="item.title" />
                    <span :class="item.img ? 'blind': 'card-logo-text'">{{item.title}}</span>
                  </div>
                </div>
              </template>
            </GroupedSlider>
          </div>
          <!-- // Swiper -->
        </div>
        <!-- // KEY PLAYERS List -->
        <!-- KEY PLAYERS List -->
        <div>
          <div>
            <!-- Swiper -->
            <GroupedSlider
              :pagination="false"
              :autoplay="false"
              :loop="false"
              :list="memberList"
              :itemsPerGroup="12"
            >
              <template v-slot="{ item }">
                <div class="card-wrap">
                  <div class="card">
                    <img v-if="item.img" :src="item.img" :alt="item.title" />
                    <span :class="item.img ? 'blind': 'card-logo-text'">{{item.title}}</span>
                  </div>
                </div>
              </template>
            </GroupedSlider>
          </div>
          <!-- // Swiper -->
        </div>
        <!-- // KEY PLAYERS List -->

      </div>
    </section>
    <!-- // KEY PLAYERS -->

    <!-- PRICE PLAN -->
    <section class="inner-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3>PRICE PLAN</h3>
          <div class="title-desc">{{$t('content.center.Gangnam.txt16')}}</div>
        </div>
        <div class="workspace-wrap">
          <ul class="workspace-list">
            <li>
              <div class="workspace">
                <figure class="ws-photo" data-aos="fade-up">
                  <img src="~@/assets/images/center/gangnam-ws-1.jpg" />
                </figure>
                <dl data-aos="fade-up" data-aos-delay="250">
                  <dt>{{$t('content.center.Gangnam.txt17')}}</dt>
                  <dd>
                    <span class="price">{{$t('content.center.Gangnam.txt18')}} ~</span>
                    / <span v-html="$t('content.center.Gangnam.txt19')"></span>
                    <p>{{$t('content.center.Gangnam.txt20')}}</p>
                    <strong>{{$t('content.center.Gangnam.txt21')}}</strong>
                    <ul class="bullet-square-list">
                      <li>{{$t('content.center.Gangnam.txt22')}}</li>
                      <li>{{$t('content.center.Gangnam.txt23')}}</li>
                      <li>{{$t('content.center.Gangnam.txt24')}}</li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <div class="workspace">
                <figure class="ws-photo" data-aos="fade-up">
                  <img src="~@/assets/images/center/gangnam-ws-2.jpg" />
                </figure>
                <dl data-aos="fade-up" data-aos-delay="250">
                  <dt>{{$t('content.center.Gangnam.txt25')}}</dt>
                  <dd>
                    <span class="price">{{$t('content.center.Gangnam.txt26')}}</span>
                    / {{$t('content.center.Gangnam.txt27')}}
                    <p>{{$t('content.center.Gangnam.txt28')}}</p>
                    <strong>{{$t('content.center.Gangnam.txt29')}}</strong>
                    <ul class="bullet-square-list">
                      <li>{{$t('content.center.Gangnam.txt30')}}</li>
                      <li>{{$t('content.center.Gangnam.txt31')}}</li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <div class="workspace">
                <figure class="ws-photo" data-aos="fade-up">
                  <img src="~@/assets/images/center/gangnam-ws-3.jpg" />
                </figure>
                <dl data-aos="fade-up" data-aos-delay="250">
                  <dt>{{$t('content.center.Gangnam.txt32')}}</dt>
                  <dd>
                    <span class="price">{{$t('content.center.Gangnam.txt33')}}</span>
                    / {{$t('content.center.Gangnam.txt34')}}
                    <p>{{$t('content.center.Gangnam.txt35')}}</p>
                    <strong>{{$t('content.center.Gangnam.txt36')}}</strong>
                    <ul class="bullet-square-list">
                      <li>{{$t('content.center.Gangnam.txt37')}}</li>
                      <li>{{$t('content.center.Gangnam.txt38')}}</li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- // PRICE PLAN -->

    <!-- Contact -->
    <section class="inner-section">
      <div class="inner-sm">
        <div class="title-wrap">
          <h3>Contact</h3>
          <div class="title-desc">{{$t('content.center.Gangnam.txt39')}}</div>
        </div>
        <div class="location-wrap" data-aos="fade-up">
          <div class="info-area">
            <dl class="gangnam">
              <dt>LOCATION</dt>
              <dd v-html="$t('content.center.Gangnam.txt40')">
              </dd>
              <dt>E-MAIL</dt>
              <dd>
                <ExternalLink
                  to="mailto:gangnam@dreamplus.asia"
                >gangnam@dreamplus.asia</ExternalLink>
              </dd>
              <dt>DIRECTION</dt>
              <dd>
                <dl class="direction">
                  <dt>{{$t('content.center.Gangnam.txt41')}}</dt>
                  <dd>
                    <ul class="subway">
                      <li>{{$t('content.center.Gangnam.txt42')}}</li>
                      <li>{{$t('content.center.Gangnam.txt43')}}</li>
                    </ul>
                  </dd>
                  <dt>{{$t('content.center.Gangnam.txt44')}}</dt>
                  <dd>
                    <ul class="bus">
                      <li>{{$t('content.center.Gangnam.txt45')}}</li>
                    </ul>
                  </dd>
                </dl>
              </dd>
              <dt>TEL</dt>
              <dd>02-520-1500</dd>
            </dl>
          </div>
          <div class="map-area">
            <img src="~@/assets/images/center/map-gangnam-center.png" alt="gangnam-center" />
            <div class="marker-wrap gangnam">
              <span class="marker" data-aos="zoom-in" data-aos-delay="300"></span>
              <span class="branch-name">Gangnam Center</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Contact -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils, mixinMotion } from '@/common/helperUtils';
import BaseSlider from '@/components/slider/BaseSlider.vue';
import GroupedSlider from '@/components/slider/GroupedSlider.vue';
import BaseTab from '@/components/base/BaseTab.vue';
import CoverTransition from '@/components/transition/CoverTransition.vue';
import ExternalLink from '@/components/base/ExternalLink.vue';

export default {
  name: 'GangnamIntro',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    BaseSlider,
    BaseTab,
    GroupedSlider,
    CoverTransition,
    ExternalLink,
  },
  data() {
    return {
      tabValue: 0,
      facilities:
        ['OFFICE LOUNGE', 'OFFICE', 'LIBRARY', 'LOBBY', 'BUSINESS LOUNGE', 'EVENT HALL', 'MULTI ROOM', 'MEDIA CENTER'],
      facilitieSlider: undefined,
      facilitieSliderIndex: undefined,
      facilitieSliderList: [
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Office-Lounge-Photo1.jpg',
          ),
          title: 'OFFICE LOUNGE1',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Office-Lounge-Photo2.jpg',
          ),
          title: 'OFFICE LOUNGE2',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Office-Photo1.jpg',
          ),
          title: 'OFFICE1',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Office-Photo2.jpg',
          ),
          title: 'OFFICE2',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Office-Photo3.jpg',
          ),
          title: 'OFFICE3',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Office-Photo4.jpg',
          ),
          title: 'OFFICE4',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Open-Library-Photo1.jpg',
          ),
          title: 'LIBRARY1',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Open-Library-Photo2.jpg',
          ),
          title: 'LIBRARY2',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Open-Library-Photo3.jpg',
          ),
          title: 'LIBRARY3',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Open-Library-Photo4.jpg',
          ),
          title: 'LIBRARY4',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Lobby-Photo1.jpg',
          ),
          title: 'LOBBY',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Business-Lounge-Photo1.jpg',
          ),
          title: 'BUSINESS LOUNGE1',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Business-Lounge-Photo2.jpg',
          ),
          title: 'BUSINESS LOUNGE2',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Business-Lounge-Photo3.jpg',
          ),
          title: 'BUSINESS LOUNGE3',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Event-Hall-Photo1.jpg',
          ),
          title: 'EVENT HALL1',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Event-Hall-Photo2.jpg',
          ),
          title: 'EVENT HALL2',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Event-Hall-Photo3.jpg',
          ),
          title: 'EVENT HALL3',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Multi-Room-Photo1.jpg',
          ),
          title: 'MULTI ROOM1',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Multi-Room-Photo2.jpg',
          ),
          title: 'MULTI ROOM2',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Multi-Room-Photo3.jpg',
          ),
          title: 'MULTI ROOM3',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Multi-Room-Photo4.jpg',
          ),
          title: 'MULTI ROOM4',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Media-Center-Photo1.jpg',
          ),
          title: 'MEDIA CENTER1',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Media-Center-Photo2.jpg',
          ),
          title: 'MEDIA CENTER2',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/facilities/GN-Media-Center-Photo3.jpg',
          ),
          title: 'MEDIA CENTER3',
        },
      ],
      memberList: [
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/bomapp.png',
          ),
          title: '보맵',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/dreamus.png',
          ),
          title: '드림어스',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/dlight.png',
          ),
          title: '디라이트',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/one-third.png',
          ),
          title: '삼분의일',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/lycl.png',
          ),
          title: '라이클',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/koreastartupforum.png',
          ),
          title: '코리아스타트포럼',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/aptner.png',
          ),
          title: '아파트너',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/voithru.png',
          ),
          title: '보이스루',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/teamwink.png',
          ),
          title: '팀윙크',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/leverslab.png',
          ),
          title: '리버스랩',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/sentbe.png',
          ),
          title: '센트비',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/clo.png',
          ),
          title: '씨엘오',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/phdkim.png',
          ),
          title: '팔루썸니',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/qmit.png',
          ),
          title: '큐엠아이티',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/welt.png',
          ),
          title: '웰트',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/raizegls.png',
          ),
          title: '레이즈지엘에스',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/wellysis.png',
          ),
          title: '웰리시스',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/bejewel.png',
          ),
          title: '비주얼',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/sixshop.png',
          ),
          title: '식스샵',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/winkstone.png',
          ),
          title: '윙크스톤파트너스',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/luxrobo.png',
          ),
          title: '럭스보로',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/monitor.png',
          ),
          title: '모니터코퍼레이션',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/designovel.png',
          ),
          title: '디자이노블',
        },
        {
          img: this.requireAssetsImage(
            'center/gangnam/members/softberry.png',
          ),
          title: '소프트베리',
        },
      ],
    };
  },
  mounted() {
    this.facilitieSlider = this.$refs.facilitieSlider;
  },
  methods: {
    onChange(v) {
      this.tabValue = this.facilities.indexOf(this.facilitieSliderList[v].title.replace(/[1-9]/g, ''));
    },
    tabCallback() {
      this.facilitieSliderList.some((v, i) => {
        const facilitieTitle = v.title.replace(/[1-9]/g, '');
        const bool = facilitieTitle === this.facilities[this.tabValue] && true;
        if (bool) {
          this.tabValue = i;
        }
        return bool;
      });
      const tmp = this.tabValue + 1;
      this.facilitieSlider.$el.swiper.slideTo(tmp);
    },
  },
};
</script>
<style>
</style>
